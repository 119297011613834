/*
 * MedGrocer logo only
 */
import React, { useState, useContext, Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faCapsules,
  faEnvelope,
  faBriefcase,
  faQuestion,
  faFileAlt,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons"

import { AppContext } from "../../context/AppContext"

import styles from "./utils/layout.module.scss"

const Navbar = ({ path }) => {
  const data = useStaticQuery(graphql`
    {
      logoSquare: file(
        relativePath: { eq: "logos/medgrocer-square-white.png" }
      ) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const navbarItems = [
    {
      name: "Order",
      link: "/epharmacy/order",
      icon: faCapsules,
      isHiddenInDesktop: false,
    },
    {
      name: "Corporate",
      link: "/corporate",
      icon: faBriefcase,
      isHiddenInDesktop: false,
    },
    {
      name: "Careers",
      link: "/careers",
      icon: faUser,
      isHiddenInDesktop: false,
    },
    {
      name: "Contact Us",
      link: "/help-center#contact-us",
      icon: faEnvelope,
      isHiddenInDesktop: false,
    },
    {
      name: "Help Center",
      link: "/help-center",
      icon: faQuestion,
      isHiddenInDesktop: true,
    },
    {
      name: "Terms and Conditions",
      link: "/terms-and-conditions",
      icon: faFileAlt,
      isHiddenInDesktop: true,
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy",
      icon: faShieldAlt,
      isHiddenInDesktop: true,
    },
  ]

  const logoSquare = data.logoSquare.childImageSharp.fixed

  const { state } = useContext(AppContext)
  const [isMenuActive, setMenuActive] = useState(false)
  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-light is-primary">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Link to="/" className="is-flex is-align-items-center">
            <span className={classNames(styles["navbar__logo"])}>
              <Img fixed={logoSquare} />
            </span>
            <span className="has-text-white has-text-weight-bold">
              {state?.websiteName}
            </span>
          </Link>
        </div>
      </nav>
      <section
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
