import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Container from "layout/Container"

import styles from "./utils/layout.module.scss"
import { AppContext } from "../../context/AppContext"
// import sitemap from "./utils/sitemap.json"

const Footer = () => {
  const { state } = useContext(AppContext)
  const currentYear = new Date().getFullYear()
  const data = useStaticQuery(graphql`
    {
      logoSquare: file(relativePath: { eq: "logos/medgrocer-square.png" }) {
        childImageSharp {
          fixed(height: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoSquare = data.logoSquare.childImageSharp.fixed

  return (
    <footer className="footer">
      <Container isCentered mobile={12} tablet={10} desktop={10} fullhd={8}>
        <div className="columns is-centered">
          <div className="column has-text-centered">
            <Link
              to="/"
              className="is-flex is-align-items-center is-justify-content-center"
            >
              <span className={classNames(styles["footer__logo"])}>
                <Img fixed={logoSquare} />
              </span>
              <span className="has-text-primary has-text-weight-bold">
                {state?.websiteName}
              </span>
            </Link>

            <p className="help pt-1 ">
              © {currentYear}, {state?.websiteName}, Inc.
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
